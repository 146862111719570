import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import SliderStyles from "@livecasino/core/v2/components/Slider/SliderStyles";
import getDeviceInfo from "@livecasino/core/helpers/getDeviceInfo";
import useGetAspectRatioSizesAndFontSize from "@livecasino/core/hooks/useGetAspectRatioSizesAndFontSize";
import useGameMobileSizesCalculation from "@livecasino/core/hooks/useGameMobileSizesCalculation";
import { POST_MESSAGE_ACTIONS } from "@livecasino/core/constants/postMessageActions";
import getParamsFromUrl from "@livecasino/core/helpers/getParamsFromUrl";
import useAuth from "@livecasino/core/hooks/useAuth";
import useSetIframeUrl from "@livecasino/core/hooks/useSetIframeUrl";
import useVolume from "@livecasino/core/hooks/useVolume";
import sendPostMessageToChildFrames from "@livecasino/core/helpers/sendPostMessageToChildFrames";
import sendPostMessageToParent from "@livecasino/core/helpers/sendPostMessageToParent";
import useStaticCallback from "@livecasino/core/hooks/useStaticCallback";
import { GAME_TYPES } from "@livecasino/core/constants/gameTypes";
import { AUTH_URL, BET_LIMITS_URL, GAME_CONFIG_URL, HELP_TRANSLATIONS_ENDPOINT } from "./constants";
import LoadingPage from "./LoadingPage/LoadingPage";
import ErrorPage from "./ErrorPage/ErrorPage";

import "./index.css";

const { isMobile } = getDeviceInfo();
const ext = isMobile ? "mobile" : "desktop";
const HelpLazy = React.lazy(() => import(`./Help/index.${ext}.js`));

const SUPPORTED_GAME_TYPES_DATA = {
    [GAME_TYPES.AMERICAN_BLACKJACK]: {
        folder: "Blackjack",
        section: "blackjack"
    },
    [GAME_TYPES.IMMERSIVE_BLACKJACK]: {
        folder: "Blackjack",
        section: "blackjack"
    },
    [GAME_TYPES.EUROPEAN_ROULETTE]: {
        folder: "Roulette",
        section: "roulette"
    },
    [GAME_TYPES.AUTO_ROULETTE]: {
        folder: "Roulette",
        section: "roulette"
    },
    [GAME_TYPES.AMERICAN_ROULETTE]: {
        folder: "AmericanRoulette",
        section: "roulette"
    },
    [GAME_TYPES.MULTIPLIER_ROULETTE]: {
        folder: "MultiplierRoulette",
        section: "roulette"
    },
    [GAME_TYPES.MULTIPLIER_AUTO_ROULETTE]: {
        folder: "MultiplierRoulette",
        section: "roulette"
    },
    [GAME_TYPES.ROULETTE_ROUGE]: {
        folder: "RouletteRouge",
        section: "rouletteRouge"
    },
    [GAME_TYPES.SPEED_BACCARAT]: {
        folder: "Baccarat",
        section: "baccarat"
    },
    [GAME_TYPES.SPEED_DRAGON_TIGER]: {
        folder: "DragonTiger",
        section: "dragonTiger"
    },
    [GAME_TYPES.SICBO]: {
        folder: "Sicbo",
        section: "sicbo"
    },
    [GAME_TYPES.TEEN_PATTI]: {
        folder: "TeenPatti",
        section: "teenPatti"
    },
    [GAME_TYPES.PIRATE_PLUNDER]: {
        folder: "PiratePlunder",
        section: "piratePlunder"
    },
    [GAME_TYPES.ANDAR_BAHAR]: {
        folder: "AndarBahar",
        section: "andarBahar"
    },
    [GAME_TYPES.ROBIN_THE_FAIR]: {
        folder: "RobinTheFair",
        section: "robinTheFair"
    },
    [GAME_TYPES.CASINO_HOLDEM]: {
        folder: "CasinoHoldem",
        section: "casinoHoldem"
    }
};

const DEFAULT_LANGUAGE_CODE = "en";

const RIGHT_TO_LEFT_LANGUAGES = ["fa"];

const params = getParamsFromUrl(window.location.href, { decode: false });

const {
    token,
    refreshToken,
    languageCode,
    gameType,
    initialVolume,
    partnerPrefix,
    playerId,
    tableId,
    translationVersion
} = params;

function App() {
    const { requestInstance } = useAuth({
        token,
        refreshToken,
        frameUrl: AUTH_URL,
        keyPrefix: partnerPrefix
    });

    const [state, setState] = useState({
        loading: true,
        data: [],
        content: {},
        languageCode: String(languageCode).toLowerCase(),
        error: ""
    });

    const { volume, setVolumeChange } = useVolume(initialVolume);

    const [translationObjForBetLimits, setTranslationObjForBetLimits] = useState(null);
    const betLimitsRequestedTranslationRef = useRef(false);

    const getTranslationObjectForBetLimits = useStaticCallback(() => {
        return translationObjForBetLimits;
    });

    const { iframeUrl: betLimitsUrl } = useSetIframeUrl({
        url: BET_LIMITS_URL,
        token,
        refreshToken,
        partnerPrefix,
        playerId,
        tableId,
        gameType
    });

    // set listeners for getting translations from game for betLimits
    useEffect(() => {
        window.addEventListener("message", handleMessage);
        // send postMessage to the game to get translation
        const messageToRequestTranslationObject = {
            action: POST_MESSAGE_ACTIONS.GET_TRANSLATION_OBJECT
        };
        sendPostMessageToParent(messageToRequestTranslationObject);

        function handleMessage(e) {
            const message = e.data;

            // get translation from the game
            if (message?.action === POST_MESSAGE_ACTIONS.TRANSLATION_OBJECT) {
                const translationsObject = JSON.parse(e.data.payload);

                setTranslationObjForBetLimits(translationsObject);

                // if betLimits already requested translation but at that time we didn't have that object
                // yet vas we didn't send it, SEND IT NOW
                if (betLimitsRequestedTranslationRef.current) {
                    sendTranslationObjToBetLimits(translationsObject);
                }
            }

            //  send translationsObject to betLimits if requested and we have it
            if (message?.action === POST_MESSAGE_ACTIONS.GET_TRANSLATION_OBJECT) {
                betLimitsRequestedTranslationRef.current = true;

                const translationObjectForBetLimits = getTranslationObjectForBetLimits();
                if (translationObjectForBetLimits) {
                    sendTranslationObjToBetLimits(translationObjectForBetLimits);
                }
            }

            function sendTranslationObjToBetLimits(translationsObject) {
                const payload = JSON.stringify(translationsObject);

                const messageToSend = {
                    action: POST_MESSAGE_ACTIONS.TRANSLATION_OBJECT,
                    payload
                };
                sendPostMessageToChildFrames(messageToSend);
            }
        }

        return () => window.removeEventListener("message", handleMessage);
    }, [getTranslationObjectForBetLimits]);

    // send postMessage to the game to get a translation when the language code is changed
    useEffect(() => {
        const messageToRequestTranslationObject = {
            action: POST_MESSAGE_ACTIONS.GET_TRANSLATION_OBJECT
        };
        sendPostMessageToParent(messageToRequestTranslationObject);
    }, [state.languageCode]);

    const isRightToLeftLanguage = useMemo(
        () => RIGHT_TO_LEFT_LANGUAGES.includes(state.languageCode),
        [state.languageCode]
    );

    const { fontSize: desktopFontSize } = useGetAspectRatioSizesAndFontSize();
    const { fontSize: mobileFontSize } = useGameMobileSizesCalculation({
        scaleRatio: 1
    });

    const fontSize = isMobile ? mobileFontSize * 0.8 : desktopFontSize * 2.5;

    useEffect(() => {
        const gameTypeFolderName = SUPPORTED_GAME_TYPES_DATA[gameType]?.folder;
        const gameTypeSectionName = SUPPORTED_GAME_TYPES_DATA[gameType]?.section;
        if (!gameTypeFolderName || !gameTypeSectionName) {
            return;
        }

        const bodyElement = document.querySelector("body");
        if (!bodyElement) {
            return;
        }

        if (isRightToLeftLanguage) {
            const directionAttribute = document.createAttribute("dir");
            directionAttribute.value = "rtl";
            bodyElement.setAttributeNode(directionAttribute);
        } else {
            bodyElement.removeAttribute("dir");
        }

        getHelpContent(gameTypeFolderName, gameTypeSectionName, state.languageCode, tableId, playerId)
            .then(({ content, helpContent }) => {
                setState(prevState => ({
                    ...prevState,
                    data: helpContent,
                    content,
                    loading: false
                }));
            })
            .catch(() => {
                setState(prevState => ({
                    ...prevState,
                    loading: false,
                    error: "Something went wrong ..."
                }));
            });

        async function getComponentsListBuilder(gameTypeFolderName) {
            const response = await import(`./games/${gameTypeFolderName}/index.js`);
            return response.default;
        }

        async function getGameConfig({ tableId, playerId }) {
            let configObj;

            try {
                if (!tableId || !playerId) {
                    await Promise.reject();
                }
                const response = await requestInstance.get(
                    `${GAME_CONFIG_URL}?tableId=${Number(tableId)}&playerId=${Number(playerId)}`
                );

                configObj = response?.data || {};
            } catch (error) {
                return {};
            }
            return configObj;
        }

        async function getDefaultLanguageContentObj(gameTypeFolderName) {
            const response = await import(`./games/${gameTypeFolderName}/content/${DEFAULT_LANGUAGE_CODE}.js`);
            return response.default;
        }

        async function getComponentsContentObj(gameTypeFolderName, gameTypeSectionName, languageCode) {
            // fetch default file from file system
            let languageFile = null;
            try {
                languageFile = await getDefaultLanguageContentObj(gameTypeFolderName);
            } catch (e) {
                console.error(e);
            }

            if (translationVersion) {
                try {
                    const url = `${HELP_TRANSLATIONS_ENDPOINT}/${gameTypeSectionName}_help/${languageCode}/${translationVersion}`;
                    const { data: translatedLanguageFile } = await requestInstance.get(url);
                    languageFile = { ...languageFile, ...translatedLanguageFile };
                } catch (e) {
                    console.error(e);
                }
            }

            return languageFile;
        }

        async function getHelpContent(gameTypeFolderName, gameTypeSectionName, languageCode, tableId, playerId) {
            const data = await getGameConfig({ tableId, playerId });
            let gameConfig = {};
            let isLanguageSelectDisabled = true;

            if (data) {
                gameConfig = {
                    ...data.configs,
                    currencyCode: data.currencyCode,
                    gameName: data.gameName,
                    plankMultipliers: data.plankMultipliers,
                    bonusCardConfiguration: data.bonusCardConfiguration
                };
                isLanguageSelectDisabled = data.configs.languages.length <= 1;
            }

            const requests = [
                getComponentsListBuilder(gameTypeFolderName),
                getComponentsContentObj(gameTypeFolderName, gameTypeSectionName, languageCode)
            ];

            const response = await Promise.all(requests);
            const builder = response[0];
            const content = response[1];

            const helpContent = builder({ content, fontSize, gameConfig, betLimitsUrl, isLanguageSelectDisabled });

            return { content, helpContent };
        }
    }, [state.languageCode, requestInstance, fontSize, isRightToLeftLanguage, betLimitsUrl]);

    useEffect(() => {
        window.addEventListener("message", handleMessage);

        function handleMessage(e) {
            const message = e.data;

            if (message?.action === POST_MESSAGE_ACTIONS.CHANGE_LANGUAGE) {
                const languageCode = String(message.payload).toLowerCase();
                setState(prevState => {
                    return {
                        ...prevState,
                        languageCode
                    };
                });
            }
            if (message?.action === POST_MESSAGE_ACTIONS.CHANGE_VOLUME) {
                setVolumeChange(message.payload);
            }
        }

        return () => window.removeEventListener("message", handleMessage);
    }, [setVolumeChange]);

    return (
        <>
            <SliderStyles />
            {state.loading && <LoadingPage />}
            {state.error && <ErrorPage text={state.error} />}
            {!state.loading && !state.error && (
                <Suspense fallback={<LoadingPage />}>
                    <HelpLazy
                        data={state.data}
                        volume={volume}
                        isRightToLeftLanguages={isRightToLeftLanguage}
                        fontSize={fontSize}
                    />
                </Suspense>
            )}
        </>
    );
}

export default App;
